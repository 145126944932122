'use client'

import { useEffect, useRef } from 'react'
import NextLink from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import rocksImage from './rocks.webp'
import NextImage from 'next/image'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
// import RepeatAnnouncement from './assets/repeat-announcement.svg'
// import BibleStudy from './assets/bible-study.svg'
// import SuitGuy from './assets/suit-guy.svg'
// import BrokenGraph from './assets/broken-graph.svg'
// import TravelFamily from './assets/travel-family.svg'
// import InfoPerson from './assets/info-person.svg'
// import TAnnouncement from './assets/t-announcement.svg'
// import WPencil from './assets/w-pencil.svg'
import ChatBubble from '@/modules/freepik/ChatBubble.svg'
import Cross from '@/modules/freepik/Cross.svg'
import Shield from '@/modules/freepik/Shield.svg'
import Question from '@/modules/freepik/Question.svg'
import Announce from '@/modules/freepik/Announce.svg'
import Book from '@/modules/freepik/Book.svg'
import Scroll from '@/modules/freepik/Scroll.svg'
import Happy from '@/modules/freepik/Happy.svg'
import styles from './WhatIsSteppingStones.module.scss'

const list = [
  { title: 'Blog Posts', icon: ChatBubble, subtitle: 'Deep thoughts and devotionals' },
  { title: 'Doctrine and Theology', icon: Book, subtitle: 'Word and doctrine' },
  { title: 'Apologetics', icon: Shield, subtitle: 'Answers, or defense of the Gospel' },
  { title: 'Polemics', icon: Announce, subtitle: 'Refutations of false teaching or false religions' },
  { title: 'Questions / Answers', icon: Question, subtitle: 'Answering Bible questions' },
  { title: 'Testimonies', icon: Happy, subtitle: 'Miracles and encouraging stories' },
  { title: 'Missions Updates', icon: Cross, subtitle: 'From the front lines' },
  { title: 'History & Archeology', icon: Scroll, subtitle: 'History is relevant to spreading the Gospel' }
]

function WhatIsSteppingStones () {
  const cardWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const cardWrapper = cardWrapperRef.current
    let scrollInterval: NodeJS.Timeout

    if (cardWrapper) {
      const startScrolling = () => {
        scrollInterval = setInterval(() => {
          cardWrapper.scrollLeft += 1
          if (cardWrapper.scrollLeft >= cardWrapper.scrollWidth - cardWrapper.clientWidth) {
            cardWrapper.scrollLeft = 0
          }
        }, 40)
      }

      // const stopScrolling = () => {
      //   clearInterval(scrollInterval)
      // }

      // cardWrapper.addEventListener('mouseenter', stopScrolling)
      // cardWrapper.addEventListener('mouseleave', startScrolling)

      startScrolling()

      return () => {
        clearInterval(scrollInterval)

        // cardWrapper.removeEventListener('mouseenter', stopScrolling)
        // cardWrapper.removeEventListener('mouseleave', startScrolling)
      }
    } else {
      console.log('Card Wrapper is null')
    }
  }, [])

  return (
    <div className={styles.whatIsSteppingStones}>
      <div className={styles.backgroundImage}>
        <NextImage
          src={rocksImage}
          width={150}
          height={200}
          layout='responsive'
          style={{ userSelect: 'none' }}
          className={styles.articleImage}
          alt={'Rocks Background Image'}
          priority
          placeholder='blur'
        />
      </div>
      <div className={styles.topWrapper}>
        <h2>Jesus said “Go make disciples of all nations…” </h2>
        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'thin' }}>
          Stepping Stones is a community of disciple makers creating and sharing content that help people grow and follow Jesus
        </p>
        <div className={styles.createAccountWrapper}>
          <NextLink href='/v1/users/signup' className={styles.link}>
            <div>Create Your Account</div>
            <div style={{ paddingTop: 3 }}>
              <ChevronRightIcon />
            </div>
          </NextLink>
        </div>
        <div className={styles.cardWrapper} ref={cardWrapperRef}>
          {[...list, ...list].map((item, i) => {
            return (
              <Card key={i} className={styles.card}>
                <CardMedia
                  sx={{ height: 140 }}
                  title={item.title}
                >
                  <div className={styles.itemImage}>
                    <NextImage
                      src={item.icon}
                      layout="responsive"
                      objectFit="cover"
                      alt={item.title}
                      width={180}
                      height={180}
                    />
                  </div>
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{ paddingTop: 14 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.9rem' }}>
                    {item.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WhatIsSteppingStones
