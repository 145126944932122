import styles from './WhyShouldCults.module.scss'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop'
import GppBadIcon from '@mui/icons-material/GppBad'
import FlakyIcon from '@mui/icons-material/Flaky'
import TranslateIcon from '@mui/icons-material/Translate'
import NextLink from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

function ListItem ({ text, icon }: { text: string, icon?: JSX.Element | null }) {
  return (
    <div className={styles.listItem}>
      {/* dot */}
      <div>{icon || null}</div>
      <p>
        {text}
      </p>
    </div>
  )
}

function WhyShouldCults () {
  return (
    <section className={styles.wrapper}>
      <h3>Why should the Christian cults rank higher on search engines than actual Christian websites?</h3>
      <div className={styles.list}>
        <ListItem
          icon={<ScreenSearchDesktopIcon fontSize='large' />}
          text={'When searching for basic Christian content outside of English, often it\'s the cults that appear at the top of searches.'}
        />
        <ListItem
          icon={<GppBadIcon fontSize='large' />}
          text={'The Gospel as taught by the Bible, and genuine Christians are frequently misrepresented to much of the world.'}
        />
        <ListItem
          icon={<FlakyIcon fontSize='large' />}
          text={'To ensure that genuine Christian teaching is represented to the unreached world, it rests on the shoulders of Christians of various backgrounds to create high ranking SEO friendly platforms.'}
        />
        <ListItem
          icon={<TranslateIcon fontSize='large' />}
          text={'Stepping Stones is a platform that aims to provide a space for Christians to create written content that is easily accessible to the world seeking to learn about the Gospel and Christianity on one platform.'}
        />
      </div>
      <NextLink href='/v1/users/signup' style={{ display: 'flex' }} className={styles.link}>
        <div>Create your account</div>
        <div style={{ paddingTop: 3 }}>
          <ChevronRightIcon />
        </div>
      </NextLink>
    </section>
  )
}

export default WhyShouldCults
