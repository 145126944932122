import Button from '@mui/material/Button'
import { Player } from '@lottiefiles/react-lottie-player'
import Christ from './christ.json'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import NextLink from 'next/link'
import styles from './Banner.module.scss'

function Banner () {
  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.leftBanner}>
          <Player
            src={Christ}
            className={styles.bannerVideo}
            autoplay
            loop
            controls
          />
        </div>
        <div className={styles.rightBanner}>
          <h1>Stepping Stones exists to help further the gospel everywhere</h1>
          <ul className={styles.bannerListWrapper}>
            <li className={styles.bannerListRow}><CheckRoundedIcon className={styles.iconStyle} /> Free to host, always</li>
            <li className={styles.bannerListRow}><CheckRoundedIcon className={styles.iconStyle} /> Free to publish, always</li>
            <li className={styles.bannerListRow}><CheckRoundedIcon className={styles.iconStyle} /> Free to access, always</li>
          </ul>
          <div className={styles.bannerActions}>
            <NextLink href={'/feed'}>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Explore /feed
              </Button>
            </NextLink>
            <NextLink href={'/topics'}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Explore /topics
              </Button>
            </NextLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
