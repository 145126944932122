import { Player } from '@lottiefiles/react-lottie-player'
import NextLink from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Christ2 from './christ-2.json'
import styles from './WhatWeNeed.module.scss'

function WhatWeNeed () {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h2>What We Need</h2>
        <div>
          <div className={styles.section}>
            <div>We need <span style={{ fontStyle: 'italic' }}>anything</span> and <span style={{ fontStyle: 'italic' }}>everything</span> that you would share with a new believer, non-believer, or even a seasoned believer.</div>
          </div>
          <div className={styles.section}>
            <div>Once a person comes to faith in Jesus - they need to become a disciple.</div>
          </div>
          <div className={styles.section}>
            <div>A disciple is a learner and a doer, they learn to do all that their teacher is teaching them. A disciple of Jesus must learn all that Jesus said and did in order to go out and do them as well.</div>
          </div>
          <div className={styles.section}>
            <div>We count on you to provide all the things every Christian ought to know.</div>
          </div>
        </div>
        <div style={{ maxWidth: 210 }}>
          <NextLink href='/v1/users/signup' style={{ display: 'flex' }} className={styles.link}>
            <div>Create Your Account</div>
            <div style={{ paddingTop: 3 }}>
              <ChevronRightIcon />
            </div>
          </NextLink>
        </div>
      </div>
      <div className={styles.video}>
        <Player
          src={Christ2}
          className={styles.videoStyle}
          autoplay
          loop
          controls
        />
      </div>
    </div>
  )
}

export default WhatWeNeed
