import Link from 'next/link'
import Image from 'next/image'
import Button from '@/components/inputs/Button'
import { IUser } from '@/interfaces/IUser'
import styles from './HomeHeader.module.scss'

function HomeHeader ({ user, wide = true }: { user: IUser | null | undefined, wide: boolean }) {
  return (
    <div className={styles.outerWrapper}>
      <div className={`${styles.innerWrapper} ${wide ? styles.innerWrapperBig : styles.innerWrapperSmall}`}>
        <div className={styles.leftSection}>
          <div className={styles.logo}>
            <Link scroll href='/feed'>
              <Image
                src={'/img/sslm-dark-icon.svg'}
                alt="Stepping Stones International Ministry Logo"
                width={50}
                height={50}
                priority
              />
            </Link>
          </div>
          <Link scroll href='/feed' className={styles.logoName}>
            Stepping Stones
          </Link>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.buttonBar}>
            <Button
              value='About'
              color='primary'
              gutterRight
              variant='text'
              linkType='internal'
              link='/about'
              className={styles.hideWhenSmall}
            />
            <Button
              value='Explore'
              color='primary'
              gutterRight
              variant='text'
              linkType='internal'
              link='/topics'
              className={styles.hideWhenSmall}
            />
            {!user &&
              <Button
                value='Log In'
                color='primary'
                gutterRight
                variant='text'
                linkType='internal'
                link='/v1/users/login'
                className={styles.hideWhenSmall}
              />
            }
            {!user &&
              <Button
                value='Sign Up'
                color='primary'
                gutterRight
                variant='text'
                linkType='internal'
                link='/v1/users/signup'
                className={styles.hideWhenSmall}
              />
            }
            {user &&
              <Button
                value='Content Suggestions'
                color='primary'
                gutterRight
                variant='text'
                linkType='internal'
                link='/content-suggestions'
                className={styles.hideWhenSmall}
              />
            }
            <Button
              value='Start Reading'
              color='primary'
              gutterRight
              variant='contained'
              linkType='internal'
              link='/feed'
              style={{ paddingLeft: 30, paddingRight: 30, borderRadius: 24 }}
              className={styles.hideWhenSmall}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHeader
