import styles from './YourContentYouOwnIt.module.scss'
import NextLink from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

function YourContentYouOwnIt () {
  return (
    <section className={styles.wrapper}>
      <h3>You Keep Your Ownership Rights.</h3>
      <p>
        You retain intellectual property rights to any content you submit, post or display.
      </p>
      <NextLink href='/v1/users/signup' style={{ display: 'flex' }} className={styles.link}>
        <div>Create your account</div>
        <div style={{ paddingTop: 3 }}>
          <ChevronRightIcon />
        </div>
      </NextLink>
    </section>
  )
}

export default YourContentYouOwnIt
