'use client'

import { ThemeProvider } from '@mui/material'
import darkTheme from '@/modules/themeService/darkTheme'
import lightTheme from '@/modules/themeService/lightTheme'

function ThemeClient ({ children, theme }: { children: React.ReactNode, theme?: string }) {
  let selectedTheme = {}
  if (theme === 'dark') {
    selectedTheme = darkTheme
  } else {
    selectedTheme = lightTheme
  }

  return (
    <ThemeProvider theme={selectedTheme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeClient
