import { ReactElement } from 'react'
import { Button } from '@mui/material'
import Link from 'next/link'
import { Player } from '@lottiefiles/react-lottie-player'
import GlitterVid from './glitter.json'
import styles from './button.module.scss'

interface ButtonProps {
  value: string | null
  color?: 'primary' | 'secondary'
  variant?: 'text' | 'outlined' | 'contained'
  gutterRight?: boolean
  gutterLeft?: boolean
  forceRight?: boolean
  disabled?: boolean
  onClick?: () => void
  link?: string
  linkType?: 'internal' | 'external'
  target?: string
  startIcon?: ReactElement,
  className?: string
  style?: object
  size?: 'small' | 'medium' | 'large'
  canonical?: boolean
  glitter?: boolean
}

interface StyleShape {
  marginRight: string | number
  marginLeft: string | number
  color: string
  className?: string
}

interface LinkButtonProps {
  link: string
  target?: string
  children: ReactElement
  className?: string
  canonical?: boolean
}

function InternalLinkButton ({ children, link, className, canonical }: LinkButtonProps) {
  return (
    <Link className={className} href={link} rel={canonical ? 'canonical' : undefined}>
      {children}
    </Link>
  )
}

function ExternalLinkButton ({ children, link, target, className, canonical }: LinkButtonProps) {
  return (
    <a href={link} className={className} target={target} rel={canonical ? 'canonical' : undefined}>
      {children}
    </a>
  )
}

function CustomButton ({
  value = null,
  onClick,
  color,
  variant,
  gutterRight,
  gutterLeft,
  forceRight,
  disabled,
  link,
  linkType,
  target,
  startIcon,
  className,
  style,
  size,
  canonical,
  glitter
}: ButtonProps) {
  const localStyle: StyleShape = { marginRight: '', marginLeft: '', color: '', ...style }
  localStyle.marginRight = gutterRight ? 8 : 0
  localStyle.marginLeft = gutterLeft ? 8 : 0
  if (forceRight) localStyle.marginLeft = 'auto'
  // if (color === 'secondary') style.color = '#FFF'

  const button = (
    <Button
      variant={variant || 'contained'}
      color={color || 'primary'}
      onClick={onClick && onClick}
      style={localStyle}
      disabled={disabled || false}
      startIcon={startIcon || undefined}
      className={linkType === undefined ? className : undefined}
      size={size || 'medium'}
    >
      {value || ''}
    </Button>
  )

  if (link && linkType) {
    if (linkType === 'internal') {
      return (
        <InternalLinkButton link={link} className={className} canonical={canonical || false}>
          <>
            {glitter &&
              <div className={styles.glitterWrapper}>
                {/* <div className={styles.playerWrapper2}>
                  <Player
                    src={GlitterVid}
                    autoplay
                    loop
                    controls
                    className={styles.player2}
                  />
                </div> */}
                <div className={styles.playerWrapper}>
                  <Player
                    src={GlitterVid}
                    autoplay
                    loop
                    controls
                    className={styles.player}
                  />
                </div>
                <div>{button}</div>
              </div>
            }
            {!glitter &&
              <>{button}</>
            }
          </>
        </InternalLinkButton>
      )
    } else if (linkType === 'external') {
      return (
        <ExternalLinkButton link={link} target={target || undefined} className={className} canonical={canonical || false}>
          {button}
        </ExternalLinkButton>
      )
    }
  }

  return button
}

export default CustomButton
