'use client'

import { useEffect } from 'react'
import NextImage from 'next/image'
import styles from './ClickThrough.module.scss'
import rocksImage from './rocks.webp'
import iphoneMockup from './iphone-15-mockup.webp'
import Button from '@mui/material/Button'
import NextLink from 'next/link'

function ClickThrough () {
  useEffect(() => {
    const scrollSpeed = 30
    // paralax effect on .phoneMockup (scroll up on scroll down)
    const phoneMockup = document.querySelector(`.${styles.phoneMockup}`) as HTMLElement

    const handleScroll = () => {
      if (phoneMockup) phoneMockup.style.top = `${14 - window.scrollY / scrollSpeed}rem` // Reduced speed by half
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section
      style={{
        display: 'block'
      }}
    >
      <div
        style={{
          maxHeight: '780px',
          minHeight: 600,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <div
          className={styles.backgroundImage}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            background: '#313131'
          }}
        >
          <NextImage
            src={rocksImage}
            width={150}
            height={200}
            layout='responsive'
            style={{ userSelect: 'none' }}
            className={styles.articleImage}
            alt={'Rocks Background Image'}
            priority
            placeholder='blur'
          />
        </div>
        <div
          className={styles.phoneMockup}
          style={{
            position: 'absolute',
            top: '14rem',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <NextImage
            src={iphoneMockup}
            width={150}
            height={200}
            layout='responsive'
            style={{ userSelect: 'none' }}
            className={styles.articleImage}
            alt={'Stepping Stones Mobile Mockup Image'}
            priority
          />
        </div>
        <div
          style={{
            position: 'absolute',
            color: '#FFF',
            top: '4rem',
            width: '100%',
            textAlign: 'center'
          }}
        >
          <h1
            className={styles.title}
            style={{
              fontSize: '5rem',
              marginBottom: '1rem'
            }}
          >
            <span>Stepping Stones</span>
          </h1>
          <h2
            className={styles.subtitle}
            style={{
              fontSize: '2rem',
              marginBottom: '1.5rem'
            }}
          >
            <span>
              Christian Teaching Platform
            </span>
          </h2>
          <h3
            className={styles.description}
            style={{
              fontSize: '1rem',
              marginBottom: '2rem'
            }}
          >
            <span>For Readers and Writers</span>
          </h3>
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'calc(332px + 2rem)',
                textAlign: 'center',
                background: 'rgba(0, 0, 0, 0.25)',
                padding: '1rem',
                borderRadius: 6
              }}
            >
              <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
                <NextLink href='/feed'>
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{
                      borderRadius: 24,
                      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                    }}
                  >
                    Start Reading
                  </Button>
                </NextLink>
              </div>
              <div style={{ marginRight: 'auto' }}>
                <NextLink href='/v1/users/signup'>
                  <Button
                    variant='outlined'
                    color='secondary'
                    style={{
                      color: '#FFF',
                      borderRadius: 24,
                      border: '1px solid #FFF' ,
                      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                      textShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                    }}
                  >
                    Create Account
                  </Button>
                </NextLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClickThrough
